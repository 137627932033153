import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {AuthService} from "../../services";
import {NavItem} from "./nav-item.entity";
import {Permission} from "../../types";

@Component({
    selector: 'app-top-nav',
    standalone: true,
    imports: [
        RouterLink
    ],
    templateUrl: './top-nav.component.html',
    styleUrl: './top-nav.component.scss'
})
export class TopNavComponent implements OnInit {
    constructor(
        private router: Router,
        private authService: AuthService,
        private elementRef: ElementRef
    ) {
    }

    showDropdown = false;

    navItems: NavItem[] = [
        {path: 'sales-dashboard', label: 'Sale Dashboard', canAccess: false},
        {
            label: 'Sales Order',
            canAccess: false,
            isDropdown: true,
            children: [
                {path: 'sales-order', label: 'Sales Order', canAccess: false},
                {path: 'sales-order-item', label: 'Sales Order Item', canAccess: false}
            ]
        },
        {path: 'production-update', label: 'Production Quantity', canAccess: false},
        // {path: 'production-planing', label: 'Production Planning', canAccess: false},
        // {
        //     label: 'Report',
        //     path: 'report',
        //     canAccess: false,
        //     isDropdown: true,
        //     children: [
        //         {path: '/reports/sales-report', label: 'Sales Report', canAccess: false},
        //         {path: '/reports/invoice-report', label: 'Invoice Report', canAccess: false}
        //     ]
        // }
    ];

    @HostListener('document:click', ['$event'])
    clickOutside(event: Event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.showDropdown = false;
        }
    }

    @HostListener('document:keydown.escape')
    onEscapePress() {
        this.showDropdown = false;
    }

    ngOnInit() {
        this.setNavigationPermissions();
    }

    // private setNavigationPermissions() {
    //     const userAuthData = sessionStorage.getItem('user-auth');
    //     if (userAuthData) {
    //         try {
    //             const userData = JSON.parse(userAuthData);
    //             const permissions: Permission[] = userData.permissions || [];
    //
    //             this.navItems = this.navItems.map(item => {
    //                 if (item.children) {
    //                     return {
    //                         ...item,
    //                         canAccess: item.children.some(child =>
    //                             permissions.some(p => p.path === child.path && p.canAccess)
    //                         ),
    //                         children: item.children.map(child => ({
    //                             ...child,
    //                             canAccess: permissions.some(p => p.path === child.path && p.canAccess)
    //                         }))
    //                     };
    //                 }
    //                 return {
    //                     ...item,
    //                     canAccess: permissions.some(p => p.path === item.path && p.canAccess)
    //                 };
    //             });
    //         } catch (error) {
    //             console.error('Error parsing permissions:', error);
    //         }
    //     }
    // }

    //Mok Permissions
    private setNavigationPermissions() {
        this.navItems = this.navItems.map(item => ({
            ...item,
            canAccess: true,
            children: item.children
                ? item.children.map(child => ({ ...child, canAccess: true }))
                : undefined
        }));
    }

    toggleDropdown(event: Event) {
        event.stopPropagation();
        this.showDropdown = !this.showDropdown;
    }

    isActive(route: string): boolean {
        return this.router.url === '/' + route || this.router.url.startsWith('/' + route + '/');
    }

    logOut() {
        this.authService.userSignOut();
    }
}