<nav class="bg-white shadow-md">
    <div class="w-full px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
            <div class="flex-shrink-0"></div>
            <div class="flex justify-center flex-grow">
                @for (item of navItems; track item.label) {
                    @if (item.canAccess && !item.isDropdown) {
                        <button
                                [routerLink]="item.path"
                                [class.active]="isActive(item.path!)"
                                class="nav-button mx-2">
                            {{ item.label }}
                        </button>
                    }

                    @if (item.canAccess && item.isDropdown) {
                        <div class="relative">
                            <button
                                    (click)="toggleDropdown($event)"
                                    class="nav-button mx-2 flex items-center"
                                    [class.active]="showDropdown">
                                {{ item.label }}
                                <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                     fill="none" viewBox="0 0 10 6">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="2" d="m1 1 4 4 4-4"/>
                                </svg>
                            </button>

                            @if (showDropdown) {
                                <div class="absolute z-10 w-48 mt-2 bg-white border border-gray-100 rounded-lg shadow-md">
                                    <div class="py-2">
                                        @for (subItem of item.children; track subItem.label) {
                                            @if (subItem.canAccess) {
                                                <a [routerLink]="subItem.path"
                                                   (click)="showDropdown = false"
                                                   class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-blue-600">
                                                    {{ subItem.label }}
                                                </a>
                                            }
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }
                }
            </div>
            <div class="mr-6">
                <button (click)="logOut()"
                        class="log-button flex items-center">
                    Logout
                </button>
            </div>
        </div>
    </div>
</nav>